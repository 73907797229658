import detectEthereumProvider from "@metamask/detect-provider";
import { Contract, ethers } from "ethers";

declare global {
  interface Window {
    ethereum: any;
  }
}

/**
 * Represents a wallet.
 */
export class Wallet {
  /**
   * The API URL for the wallet.
   *
   * @type {string}
   * @private
   */
  private declare apiUrl: string;

  /**
   * Constructs a new Wallet instance.
   *
   * @param {string} apiUrl The API URL for the wallet.
   */
  constructor(apiUrl: string) {
    if (apiUrl === null || apiUrl === undefined) {
      throw new Error("API URL requied");
    } else {
      this.apiUrl = apiUrl;
    }
  }

  /**
   * Starts the application.
   *
   * @param {unknown} provider The provider to use.
   */
  private startApp(provider: unknown) {
    if (provider !== window.ethereum) {
      console.error("Do you have multiple wallets installed?");
    }
  }

  /**
   * Gets the first account from MetaMask.
   *
   * @returns {Promise<string>} A promise that resolves to the account address.
   */
  async getAccount(): Promise<string> {
    const provider = await detectEthereumProvider();
    if (provider) {
      this.startApp(provider);
    } else {
      console.log("Please install MetaMask!");
    }
    const accounts = await window.ethereum
      .request({ method: "eth_requestAccounts" })
      .catch((err: any) => {
        if (err.code === 4001) {
          console.error("Please connect to MetaMask.");
        } else {
          console.error(err);
        }
      });
    return accounts[0];
  }

  /**
   * Gets the chain ID of the current network.
   *
   * @returns {Promise<string>} A promise that resolves to the chain ID.
   */
  async getChainId(): Promise<string> {
    const chainId = await window.ethereum
      .request({ method: "eth_chainId" })
      .catch((err: any) => {
        if (err.code === 4001) {
          console.error("Please connect to MetaMask.");
        } else {
          console.error(err);
        }
      });
    return chainId;
  }

  /**
   * Connects to a contract with the given address and ABI.
   *
   * @param {string} address The address of the contract to connect to.
   * @param {string[]} abi The ABI of the contract.
   * @returns {Promise<Contract>} A promise that resolves to the contract instance.
   */
  private async connectContract(
    address: string,
    abi: string[]
  ): Promise<Contract> {
    const provider = await detectEthereumProvider();
    if (provider) {
      this.startApp(provider);
    } else {
      console.warn("Please install MetaMask!");
    }
    const signer = new ethers.providers.Web3Provider(
      window.ethereum
    ).getSigner();
    const contract = new ethers.Contract(address, abi, signer);
    return contract;
  }

  /**
   * Reads from a contract function with the given address, ABI, function name, and parameters.
   *
   * @param {string} address The address of the contract to read from.
   * @param {string[]} abi The ABI of the contract.
   * @param {string} functionName The name of the function to read from.
   * @param {unknown[]} params The parameters to pass to the function.
   * @returns {Promise<unknown>} A promise that resolves to the result of the function call.
   */
  async readContractFunction(
    address: string,
    abi: string[],
    functionName: string,
    params: unknown[]
  ): Promise<unknown> {
    const contract = await this.connectContract(address, abi);
    const result = await contract[functionName](...params);
    return result;
  }

  /**
   * Writes to a contract function with the given address, ABI, function name, parameters, and value.
   *
   * @param {string} address The address of the contract to write to.
   * @param {string[]} abi The ABI of the contract.
   * @param {string} functionName The name of the function to write to.
   * @param {unknown[]} params The parameters to pass to the function.
   * @param {string} value The value to send with the transaction, if any.
   * @returns {Promise<string>} A promise that resolves to the hash of the transaction.
   */
  async writeContractFunction(
    address: string,
    abi: string[],
    functionName: string,
    params: unknown[],
    value?: string
  ): Promise<string> {
    const contract = await this.connectContract(address, abi);
    if (value === undefined || value === "" || value === null) {
      const result = await contract[functionName](...params);
      return result;
    } else {
      const transaction = await contract.functions[functionName](...params, {
        value: ethers.utils.parseUnits(value, "18"),
      });
      return transaction.hash;
    }
  }

  /**
   * Gets a captcha image for the given address.
   *
   * @param {string} address The address to get the captcha for.
   * @returns {Promise<string>} A promise that resolves to a URL pointing to the captcha image.
   */
  async getCaptcha(address: string): Promise<string> {
    try {
      const response = await this.get(
        "auth/accounts/captcha?address=" + address
      );
      if (response.status === 200) {
        return URL.createObjectURL(await response.blob());
      } else {
        const { message } = await response.json();
        return message;
      }
    } catch (error: any) {
      return error;
    }
  }

  /**
   * Signs in the user with the given address and captcha.
   *
   * @param {string} address The address of the user to sign in.
   * @param {string} captcha The captcha entered by the user.
   * @returns {Promise<unknown>} A promise that resolves to an object with the following properties:
   *   - `nonce`: The nonce for the signed in user.
   *   - `message`: A message indicating whether the sign in was successful or not.
   */
  async signin(address: string, captcha: string): Promise<unknown> {
    let payload = {};
    try {
      const response = await this.get(
        "auth/accounts/nonce?address=" + address + "&captcha=" + captcha
      );
      if (response.status === 200) {
        const { data } = await response.json();
        const message = await this.setMessage(address, data);
        const signature = await this.personalSign(address, message, data);
        payload = {
          address: address,
          message: message,
          signature: signature,
        };
      } else {
        const { message } = await response.json();
        return message;
      }
    } catch (error) {
      return error;
    }

    try {
      const response = await this.post("auth/accounts/login", payload);
      if (response.status === 200) {
        const { data } = await response.json();
        return data;
      } else {
        const { message } = await response.json();
        return message;
      }
    } catch (error) {
      return error;
    }
  }

  private async personalSign(
    address: string,
    message: string,
    nonce: string
  ): Promise<string> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const from = address;
        const msg = `0x${Buffer.from(message, "utf8").toString("hex")}`;
        const signature = await window.ethereum.request({
          method: "personal_sign",
          params: [msg, from, nonce],
        });
        resolve(signature);
      } catch (error) {
        reject(error);
      }
    });
  }

  private setMessage(address: string, nonce: string): Promise<string> {
    return new Promise((resolve) => {
      const message = `Welcome to Astra OmniRise!\naddress\n${address.toLowerCase()}\nnonce\n${nonce}\n`;
      resolve(message);
    });
  }

  private async get(url: string) {
    const response = await fetch(this.apiUrl + url, {
      method: "GET",
      redirect: "follow",
    });
    return response;
  }

  private async post(url: string, params: object) {
    const response = await fetch(this.apiUrl + url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify(params),
    });
    return response;
  }
}
